import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Text from "@ui/text";
import Button from "@ui/button";
import Accordion from "@ui/accordion";
import {
  IntroWrapper,
  LeftBox,
  Styledtitle,
  RightBox,
  LogosWrap,
  LogoBox,
  BottomText,
} from "./stye";
import Anchor from "@ui/anchor";
// import marketingVideo from '../../../data/videos/marketingvideo.mp4';
const IntroArea = ({ data, layout, ...props }) => {
  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <LeftBox>
              <Styledtitle dangerouslySetInnerHTML={{ __html: data.texts?.[0].content }}></Styledtitle>
              <div className="d-flex align-items-center gap-4">
                {data.images[2]?.src &&
                  <Image
                    src={data.images[2]?.src}
                    alt={data.images[2]?.alt || "MS Teams"}
                    title={data.images[2]?.alt || "MS Teams"}
                  />
                }
                {data.images[3]?.src &&
                  <Image
                    src={data.images[3]?.src}
                    alt={data.images[3]?.alt || "Webex"}
                    title={data.images[3]?.alt || "Webex"}
                  />
                }
                {data.images[4]?.src &&
                  <Image
                    src={data.images[4]?.src}
                    alt={data.images[4]?.alt || "Zoom"}
                    title={data.images[4]?.alt || "Zoom"}
                  />
                }
              </div>
              <Styledtitle dangerouslySetInnerHTML={{ __html: data.texts?.[1]?.content }}></Styledtitle>

              {data?.items && <Accordion data={data?.items} layout={2} />}
            </LeftBox>
            {data?.buttons?.map(({ id, index, content, ...rest }) => (
              <div key={id} className="mt-lg-5 mt-4">
                <Button m="7px" {...rest}>
                  {content}
                </Button>
              </div>
            ))}
            {data.headings?.[0]?.content && (
                <BottomText>
                    {data.headings[0].content}
                </BottomText>
            )}
            <LogosWrap>
              <LogoBox>
                {data.images[1]?.src && (
                  <Anchor
                    path={
                      data?.images[1]?.alt === "Andovar Studios"
                        ? "https://andovarstudios.com/"
                        : data.images[1]?.alt === "AndData"
                        ? "https://anddata.ai/"
                        : data.images[1]?.alt === "Andovar eLearning"
                          ? "https://www.elearning-localization.com/"
                          : data.images[1]?.alt === "Andovar Gaming"
                            ? "https://www.andovar-game-localization.com/"
                            : ""
                    }
                    target="_blank"
                  >
                    <Image
                      src={data.images[1]?.src}
                      alt={data.images[1]?.alt || "Andovar Studios"}
                      title={data.images[1]?.alt || "Andovar Studios"}
                    />
                    <Text as="span">
                      {data.images[1]?.alt === "Andovar Studios"
                        ? "Visit Andovar Studios"
                        :data.images[1]?.alt == "AndData"
                        ? "Visit AndData"
                        : data.images[1]?.alt === "Andovar eLearning"
                          ? "Visit Andovar eLearing"
                          : data.images[1]?.alt === "Andovar Gaming"
                            ? "Visit Andovar Gaming"
                            : ""}
                      <i className="ms-2 fal fa-angle-right"></i>
                    </Text>
                  </Anchor>
                )}
              </LogoBox>
            </LogosWrap>
          </Col>
          <Col lg={5}>
            <RightBox>
              {/* {media === "video" && media != undefined ?
              <video controls>
                <source src={marketingVideo}></source>
              </video>
            : */}
              <Image
                src={data.images[0]?.src}
                alt={data.images[0]?.alt || "Info"}
                title={data.images[0]?.alt || "Info"}
              />
              {/* } */}
            </RightBox>
          </Col>
        </Row>
      </Container>
    </IntroWrapper>
  );
};

IntroArea.propTypes = {
  layout: PropTypes.oneOf([1, 2]),
};
IntroArea.defaultProps = {
  layout: 1,
};
export default IntroArea;
